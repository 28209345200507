<template>
  <fm-modal
    v-if="openDialogPx"
    :value="openDialogPx"
    width="50%"
    v-loading="loading"
    :mask-closable="false"
    theme="mh-withe"
    @cancel="openDialogPx = false">
    <fm-title
      title-text="排序"
      :title-menus="[{key: 'sjpx', label: '随机排序'}]"
      @clickTitleMenu="clickTitleMenu">
    </fm-title>
    <fm-table-new
      @on-column-config-update="(columns) => $onTableColumnUpdate(columns, 'px')"
      v-loadingx="loading"
      :columns="baseColumnsPx"
      :data-list="beCheckList"
      :stripe="false">
    </fm-table-new>
  </fm-modal>
</template>

<script>
import {
  appraisalGroupDetailRequest
} from '@/api'

export default {
  props: {
    appraisalId: {
      type: [String, Number]
    }
  },
  data () {
    return {
      openDialogPx: false,
      loading: false,
      beCheckList: []
    }
  },
  computed: {
    baseColumnsPx () {
      return [{
        field: 'userName',
        title: '执行人'
      },
      {
        field: 'represent',
        title: '代表单位'
      },
      {
        field: 'remark',
        title: '说明'
      },
      {
        title: '顺序',
        field: 'orderNumText',
        align: 'left',
        width: 120,
        render: (h, row) => {
          return h('fm-poper-select', {
            props: {
              options: this.getOrderNums(row),
              confirm: true
            },
            on: {
              change: type => {
                type !== row.type && this.onFieldChange(row, type)
              }
            }
          }, [h('div', {
            class: {
              'ready-bahr-order-num': true
            },
            domProps: {
              innerHTML: row.orderNumText
            }
          })])
        }
      }]
    }
  },
  watch: {
    openDialogPx (value) {
      if (value) {
        this.loadData()
      }
    }
  },
  methods: {
    dealShowBkhr (dataList) {
      dataList.sort((a, b) => a.orderNumText - b.orderNumText)
      return dataList
    },
    async orderBecheck () {
      this.loading = true
      let i = 0
      let randomList = []
      while (i < this.beCheckList.length) {
        randomList.push({
          od: Math.random(),
          data: this.beCheckList[i]
        })
        i += 1
      }
      randomList.sort((a, b) => a.od - b.od)
      let allP = []
      i = 0
      while (i < randomList.length) {
        if (randomList[i].data.orderNum !== i + 1) {
          allP.push(appraisalGroupDetailRequest.update(randomList[i].data.id, {orderNum: i + 1}))
        }
        i += 1
      }
      await Promise.all(allP)
      this.$emit('srotChange')
      this.loading = false
      this.$notice.success({
        title: '系统提示',
        desc: '随机排序完成'
      })
      this.loadData()
    },
    getOrderNums (data) {
      let ops = this.beCheckList.map((v, i) => String(i + 1))
      ops = ops.filter(v => v != data.orderNum)
      return ops.map(v => {
        return {value: Number(v), label: v}
      })
    },
    async onFieldChange (row, value) {
      this.loading = true
      let data = this.beCheckList.find(v => v.orderNum === value)
      if (data) {
        await appraisalGroupDetailRequest.update(data.id, {orderNum: row.orderNum})
      }
      await appraisalGroupDetailRequest.update(row.id, {orderNum: value})
      this.$notice.success({
        title: '系统提示',
        desc: '排序修改完成'
      })
      this.$emit('srotChange')
      this.loading = false
      this.loadData()
    },
    clickTitleMenu () {
      this.orderBecheck()
    },
    loadData () {
      if (!this.appraisalId) {
        return
      }
      this.loading = true
      let parm = {
        appraisalId: this.appraisalId,
        appraisalGroupType: 'beCheck'
      }
      appraisalGroupDetailRequest.get(parm).then((data) => {
        data.forEach((v) => {
          v.orderNumText = v.orderNum === null ? '无' : v.orderNum
        })
        this.beCheckList = data
        this.loading = false
      })
    }
  }
}
</script>

<style lang="less">
.ready-bahr-order-num {
  background: rgba(47, 91, 234, 0.1);
  color: #2f5bea;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #2f5bea;
  width: 2rem;
  height: 2rem;
}
</style>